<div class="sid__root">
    <div class="sid__form">
        <button id="close-button" mat-fab color="" (click)="onCloseClick()">
            <mat-icon svgIcon="close"></mat-icon>
        </button>
        <div class="page__header">
            Идентификация<br>
            пользователя
        </div>

        <div class="form__step"
             [ngClass]="{'form__step--current': isChannelStep$ | async, 'form__step--passed': isChannelStepPassed$ | async}"
             (click)="onChannelStepClick()">
            <div class="step__header">
                <div class="header__icon">
                    <img *ngIf="!(isChannelStepPassed$ | async)"
                        src="/assets/images/simplified-id/step-header-id.svg">
                    <img *ngIf="isChannelStepPassed$ | async"
                        src="/assets/images/simplified-id/step-header-passed.svg">
                </div>
                <span>Основной канал</span>
            </div>
            <div class="step__body">
                <form [formGroup]="channelFormGroup">
                    <mat-form-field>
                        <mat-hint class="counter" matSuffix align="end">{{channelText.length}} / 100</mat-hint>
                        <input id="channelTextInput"
                                  formControlName="channelText"
                                  matInput
                                  required
                                  maxlength="100">
                        <mat-error *ngIf="channelFormGroup.controls.channelText.errors">Заполните поле</mat-error>
                    </mat-form-field>
                </form>
                <app-new-button type="blue" (click)="onConfirmChannel()">
                    Продолжить
                </app-new-button>
            </div>
        </div>

        <div class="form__step"
             [ngClass]="{'form__step--current': isContactsStep$ | async, 'form__step--passed': isContactsStepPassed$ | async}"
             (click)="onContactsStepClick()">
            <div class="step__header">
                <div class="header__icon">
                    <img *ngIf="!(isContactsStepPassed$ | async)"
                         src="/assets/images/simplified-id/step-header-additional-id.svg">
                    <img *ngIf="isContactsStepPassed$ | async"
                         src="/assets/images/simplified-id/step-header-passed.svg">
                </div>
                <span>Контакты для связи</span>
            </div>
            <div class="step__body">
                <form [formGroup]="contactsFormGroup">
                    <mat-form-field>
                        <input id="contactsTextInput"
                               formControlName="contactsText"
                               matInput
                               maxlength="100">
                    </mat-form-field>
                </form>
                <app-new-button type="blue" (click)="onConfirmContacts()" >
                    Продолжить
                </app-new-button>
            </div>
        </div>

        <div class="form__step"
             [ngClass]="{'form__step--current': isAboutMeStep$ | async, 'form__step--passed': isAboutMeStepPassed$ | async}"
             (click)="onAboutMeStepClick()">
            <div class="step__header">
                <div class="header__icon">
                    <img src="/assets/images/simplified-id/step-header-confirmation.svg" *ngIf="!(isAboutMeStepPassed$ | async)">
                    <img src="/assets/images/simplified-id/step-header-passed.svg" *ngIf="isAboutMeStepPassed$ | async">
                </div>
                <span>Расскажите о себе</span>
            </div>
            <div class="step__body">
                <form [formGroup]="aboutMeFormGroup">
                    <mat-form-field>
                        <mat-hint class="counter" matSuffix align="end">{{aboutMeText.length}} / 500</mat-hint>
                        <textarea id="aboutMeTextInput"
                                  formControlName="aboutMeText"
                                  matInput
                                  cdkTextareaAutosize="true"
                                  maxlength="500"></textarea>
                    </mat-form-field>
                </form>
                <app-new-button type="blue"
                                (click)="onCompleteAboutMe()"
                                [disabled]="!isCompleteButtonEnabled">
                    Завершить
                </app-new-button>
            </div>
        </div>
    </div>

    <div class="sid__explanation">
        <div class="expl__block" [ngClass]="{'expl__block--visible': isChannelStep$ | async}">
            <div class="expl__icon expl__icon--channel">
                <img src="/assets/images/simplified-id/step-channel.png" class="icon__shine">
                <img src="/assets/images/simplified-id/step-channel.png">
            </div>
            <div class="expl__header">
                Укажите свой основной канал
            </div>
            <div class="expl__text">
                Основной канал - это канал, где у вас больше всего подписчиков.
                Это может быть Twitch, Youtube, Telgram канал или аккаунт в любой социальной сети.
                <br><br>
                Мы просим вас рассказать о себе, чтобы убедиться, что наш сервис используется по назначению.
                Donatty - финансовый сервис, поэтому по закону мы должны защищать донатеров и стримеров от мошенников.
            </div>
        </div>

        <div class="expl__block" [ngClass]="{'expl__block--visible': isContactsStep$ | async}">
            <div class="expl__icon expl__icon--contacts">
                <img src="/assets/images/simplified-id/step-contacts.png" class="icon__shine">
                <img src="/assets/images/simplified-id/step-contacts.png">
            </div>
            <div class="expl__header">
                Расскажите, как нам с вами связаться
            </div>
            <div class="expl__text">
                Если нам потребуются дополнительные данные от вас или подтверждение, что вы это вы,
                мы напишем вам по этим контактам.
                Это может быть мессенджер, электронная почта (которую вы читаете),
                или аккаунт в социальной сети с открытыми личными сообщениями.
            </div>
        </div>

        <div class="expl__block" [ngClass]="{'expl__block--visible': isAboutMeStep$ | async}">
            <div class="expl__icon expl__icon--about-me">
                <img src="/assets/images/simplified-id/step-about-me.png" class="icon__shine">
                <img src="/assets/images/simplified-id/step-about-me.png">
            </div>
            <div class="expl__header">
                Расскажите немного о себе
            </div>
            <div class="expl__text">
                Это не обязательно, но поможет нам разобраться, если ваш случай необычный.
                Например, если у вас нет основного канала, или там что-то совсем непонятное.
                Ну, или можете просто передать нам привет в этом поле.
            </div>
        </div>
    </div>
</div>
