import {
    AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild
} from "@angular/core";
import {FinanceService} from "../../../services/finance/finance.service";
import {ToastrService} from "ngx-toastr";
import {
    ConfirmationModalComponent
} from "../../../../../../shared/src/lib/confirmation-modal/confirmation-modal.component";
import {AnalyticsEvent, EventAction, EventCategory} from "../../../../../../shared/src/lib/models/analytics-events";
import {DataLayerService} from "../../../../../../shared/src/lib/data-layer.service";
import {
    catchError, debounceTime, filter, finalize, map, mergeMap, shareReplay, skipWhile, switchMap, take, takeUntil, tap
} from "rxjs/operators";
import {FormControl, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {
    BillingRates,
    Currency, OrderState, OrderStateEvent, PayoutMethod, PayoutMethodType, WithdrawalState
} from "../../../../../../shared/src/lib/common/FinancialOperation";
import {MatStepper} from "@angular/material/stepper";
import {BehaviorSubject, combineLatest, iif, merge, Observable, of, Subject} from "rxjs";
import {DecimalPipe} from "@angular/common";
import {ShowAlwaysErrorStateMatcher} from "../../../../../../shared/src/lib/ShowAlwaysErrorStateMatcher";
import {FinanceSSEService} from "../../../services/finance/finance-sse.service";
import {PayoutCardComponent} from "./methods/card/payout-card.component";
import {PhoneNumberService} from "../../../../../../shared/src/lib/phone-number.service";
import _ from "lodash";

type InternalState = "inProgress" | "success" | "failed";

@Component({
    selector: "app-payout",
    templateUrl: "./payout.component.html",
    styleUrls: ["./payout.component.scss"]
})
export class PayoutComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {

    public readonly amount$ = new BehaviorSubject<number>(null);
    private readonly initialAmount$ = this.financeSSEService.balanceLimits$.pipe(
        filter(([balance]) => balance > 0),
        map(([balance, limits]) => Math.floor(Math.min(balance, limits.current.debit.max))),
        take(1),
    );
    public readonly amountMaxLength$ = this.financeSSEService.limits$.pipe(
        map(limits => this.decimalPipe.transform(limits.current.debit.max).length + 3),
    );

    public payoutMethod: PayoutMethod;
    public readonly withdrawalMethodToDelete$ = new BehaviorSubject<PayoutMethod>(null);
    private readonly withdrawalMethodDeleted$: Subject<void> = new Subject();
    public readonly withdrawalMethods$: Observable<Array<PayoutMethod>> = merge(
        this.amount$,
        this.withdrawalMethodDeleted$.pipe(switchMap(() => this.amount$)),
    ).pipe(
        debounceTime(500),
        filter(amount => amount > 0),
        switchMap(amount => this.financeService.getWithdrawalMethods(amount)),
        shareReplay(1),
    );
    public readonly saveWithdrawalMethod$: Subject<void> = new Subject();
    public readonly withdrawalOrder$: Subject<OrderStateEvent<WithdrawalState>> = new Subject<OrderStateEvent<WithdrawalState>>();
    public readonly withdrawalReady$: Observable<OrderStateEvent<WithdrawalState>> = this.withdrawalOrder$.pipe(
        shareReplay(1),
        filter((withdrawalState: OrderStateEvent<WithdrawalState>) =>
            withdrawalState.state === OrderState.ReadyForWithdrawal ||
            withdrawalState.state === OrderState.ReadyForWithdrawalSuspicious),
    );
    public readonly withdrawalOrderError$ = this.withdrawalOrder$
        .pipe(filter((withdrawalState: OrderStateEvent<WithdrawalState>) =>
            withdrawalState.state === OrderState.Failed));

    public readonly proposeToSaveWithdrawalMethod$ = combineLatest([this.withdrawalOrder$, this.withdrawalMethods$]).pipe(
        map(([order, methods]) =>
            !methods.find(method => method.displayName === order.data.withdrawal.method.displayName)),
        shareReplay(1),
    );

    public readonly currency = Currency.RUB;
    public state: InternalState = "inProgress";
    private readonly decimalPipe = new DecimalPipe("RU-ru");

    public finishing = false;

    public readonly withdraw$: Subject<void> = new Subject();
    private readonly withdrawalComplete$ = combineLatest([this.withdrawalReady$, this.withdraw$]).pipe(
        skipWhile(() => this.finishing),
        tap(() => this.finishing = true),
        mergeMap(([order]) => iif(
            () => order.state === OrderState.ReadyForWithdrawalSuspicious,
            of(true),
            this.financeService.setOrderState<WithdrawalState>(order.refId, OrderState.Withdraw),
        )));

    @ViewChild("cardDeleteDialog")
    private cardDeleteDialog: ConfirmationModalComponent;

    @ViewChild("stepper")
    public stepper: MatStepper;

    public readonly operationForm = new FormGroup({
        method: new FormControl<string>("", Validators.required),
        operation: new FormControl<string>(null),
    });

    private readonly destroy$: Subject<void> = new Subject();

    private readonly limitsBalance$ = combineLatest([
        this.financeSSEService.limits$, this.financeSSEService.balance$
    ]);

    public readonly payoutForm = new FormGroup({
        amount: new FormControl<string>("0", Validators.compose([
                Validators.required,
                control => {
                    if (isNaN(PayoutComponent.parseAmount(control.value))) {
                        return {required: {value: control.value}};
                    }
                    return null;
                }]),
            control => this.limitsBalance$.pipe(take(1), map(([limits, balance]) => {
                const errors: ValidationErrors = {};
                const amountNum = PayoutComponent.parseAmount(control.value);
                if (amountNum < limits.current.debit.min) {
                    errors.tariffMin = {value: control.value};
                }
                if (amountNum > limits.current.debit.max) {
                    errors.tariffMax = {value: control.value};
                }
                if (amountNum > balance) {
                    errors.balanceMax = {value: control.value};
                }
                return errors;
            }))),
        operation: this.operationForm,
    });

    public showAlwaysMatcher = new ShowAlwaysErrorStateMatcher();

    public readonly firstStepControl = new FormGroup({
        amount: this.payoutForm.get("amount"),
        lock: new FormControl<boolean>(null),
    });

    constructor(private readonly dataLayerService: DataLayerService,
                public readonly changeDetectorRef: ChangeDetectorRef,
                public readonly financeService: FinanceService,
                public readonly financeSSEService: FinanceSSEService,
                private readonly toastr: ToastrService) {
    }

    public ngAfterViewChecked(): void {
        // Required for inner forms to update
        this.changeDetectorRef.detectChanges();
    }

    @ViewChild(PayoutCardComponent)
    public payoutCardComponent: PayoutCardComponent;

    public ngAfterViewInit(): void {
        const onInitStep = [
            () => this.firstStepControl.get("lock").setErrors({incorrect: true}),
            () => {
                // Prevent next step bypassing internal form
                this.operationForm.get("operation").setErrors({incorrect: true});
                if (this.payoutMethod?.type === PayoutMethodType.CARD && this.payoutMethod.isNew) {
                    this.payoutCardComponent.initWithdrawal$.next();
                }
            },
        ];

        const notifyStepChange = (step: number) => {
            const dataLayerEvent: AnalyticsEvent = {
                eventCategory: EventCategory.Withdraw,
                eventAction: EventAction.View,
                eventLabel: `step${step}`,
            };
            if (step === 3) {
                dataLayerEvent.eventValue = this.payoutMethod.type.toLowerCase();
            }
            this.dataLayerService.emit(dataLayerEvent);
        };
        notifyStepChange(this.stepper.selectedIndex + 1);

        this.stepper.selectionChange
            .pipe(
                tap(event => notifyStepChange(event.selectedIndex + 1)),
                filter(event => !!onInitStep[event.selectedIndex]),
                takeUntil(this.destroy$),
            ).subscribe(event => onInitStep[event.selectedIndex]());
    }

    public ngOnInit() {
        this.state = "inProgress";
        this.payoutForm.get("amount").valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(amount => this.patchAmount(amount));

        this.firstStepControl.get("lock").setErrors({lock: true});

        this.proposeToSaveWithdrawalMethod$.pipe(takeUntil(this.destroy$)).subscribe();
        combineLatest([this.operationForm.get("method").valueChanges, this.withdrawalMethods$])
            .pipe(takeUntil(this.destroy$))
            .subscribe(([methodId, methods]) => {
                this.payoutMethod = methods.find(it => it.id === methodId);
                this.changeDetectorRef.detectChanges();
            });

        this.initialAmount$.subscribe(initialAmount => this.patchAmount(initialAmount));

        this.withdrawalReady$
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.operationForm.get("operation").setErrors(null);
                this.stepper.next();
                this.changeDetectorRef.detectChanges();
            });

        this.withdrawalOrderError$
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.state = "failed";
                this.changeDetectorRef.detectChanges();
            });

        combineLatest([this.withdrawalReady$, this.saveWithdrawalMethod$]).pipe(
            switchMap(([order]) => this.financeService.saveWithdrawalMethod(order.refId)),
            catchError(e => {
                this.toastr.error("Не получилось сохранить карту");
                console.error("Не получилось сохранить карту", e);
                throw new Error(e.message);
            }),
            takeUntil(this.destroy$),
        ).subscribe();

        this.withdrawalComplete$.pipe(
            catchError(e => {
                this.state = "failed";
                console.error("Withdrawal failed", e);
                throw new Error("Withdrawal failed");
            }),
            finalize(() => this.finishing = false),
            takeUntil(this.destroy$),
        ).subscribe(() => {
            this.state = "success";
            this.dataLayerService.emit({
                eventCategory: EventCategory.Withdraw,
                eventAction: EventAction.View,
                eventLabel: "sent",
                eventValue: this.payoutMethod.type.toLowerCase(),
            });
        });
    }

    public ngOnDestroy() {
        this.destroy$.next();
    }

    public retry() {
        this.state = "inProgress";
    }

    public async deleteMethod(method: PayoutMethod) {
        console.log("deleteMethod, id=", method.id, "isNew=", method.isNew);

        if (method.isNew) {
            this.toastr.error("Нельзя удалить дефолтный способ вывода");
            return;
        }

        const onError = e => {
            this.toastr.error("Не получилось удалить карту");
            console.error("Не получилось удалить карту", e);
        };

        try {
            await this.financeService
                .deleteWithdrawalMethod(method.id)
                .subscribe(
                    () => {
                        this.withdrawalMethodToDelete$.next(null);
                        this.changeDetectorRef.detectChanges();
                    },
                    onError);
            this.withdrawalMethodDeleted$.next();
        } catch (e) {
            onError(e);
        }
    }

    public onDeleteMethod(method: PayoutMethod) {
        this.withdrawalMethodToDelete$.next(method);
        this.cardDeleteDialog.show();
        this.changeDetectorRef.detectChanges();
    }

    public formatDisplayName(displayName: string, methodId: string): Observable<string> {
        return this.withdrawalMethods$.pipe(
            map(methods => methods.find(m => m.id === methodId)),
            filter<PayoutMethod>(Boolean),
            map(method => {
                if (method.type === PayoutMethodType.CARD) {
                    return "**** " + displayName.slice(-4);
                } else if (method.type === PayoutMethodType.QIWI) {
                    return PhoneNumberService.getFullNumber(displayName);
                } else {
                    return displayName;
                }
            }),
        );
    }

    public readonly commission$: Observable<{ [key in PayoutMethodType]: string }> = this.financeSSEService.rates$.pipe(
        map(rates => {
            const { debitRates } = rates;

            return {
                [PayoutMethodType.CARD]: PayoutComponent.fmtCardCommissionText(debitRates),
                [PayoutMethodType.YANDEX]: `на ЮMoney: ${debitRates?.[PayoutMethodType.YANDEX]?.[0]?.percent || "?"}%`,
                [PayoutMethodType.QIWI]: `на QIWI: ${debitRates?.[PayoutMethodType.QIWI]?.[0].percent || "?"}%`,
            };
        }),
    );

    public readonly labels = {
        stepHeader: {
            [PayoutMethodType.CARD]: "Карта",
            [PayoutMethodType.YANDEX]: "Кошелёк ЮMoney",
            [PayoutMethodType.QIWI]: "Кошелёк QIWI",
        },
        payoutMethodTitle: {
            [PayoutMethodType.CARD]: "Номер карты получателя",
            [PayoutMethodType.YANDEX]: "Номер кошелька ЮMoney",
            [PayoutMethodType.QIWI]: "Номер кошелька QIWI",
        },
        savedMethodType: {
            [PayoutMethodType.CARD]: "Карта",
            [PayoutMethodType.YANDEX]: "ЮMoney",
            [PayoutMethodType.QIWI]: "QIWI",
        },
        payoutButtonTitle: {
            [PayoutMethodType.CARD]: "Вывести на карту",
            [PayoutMethodType.YANDEX]: "Вывести на ЮMoney",
            [PayoutMethodType.QIWI]: "Вывести на QIWI",
        },
        deleteDlgPayoutMethod: {
            [PayoutMethodType.CARD]: `сохранённую карту`,
            [PayoutMethodType.YANDEX]: `сохранённый кошелёк ЮMoney`,
            [PayoutMethodType.QIWI]: `сохранённый кошелёк QIWI`,
        },
        deleteDlgHeader: {
            [PayoutMethodType.CARD]: `Удаление карты`,
            [PayoutMethodType.YANDEX]: `Удаление кошелька`,
            [PayoutMethodType.QIWI]: `Удаление кошелька`,
        },
        deleteDlgConfirmText: {
            [PayoutMethodType.CARD]: `Удалить карту`,
            [PayoutMethodType.YANDEX]: `Удалить кошелёк`,
            [PayoutMethodType.QIWI]: `Удалить кошелёк`,
        }
    };

    private patchAmount(amount) {
        const amountNum = PayoutComponent.parseAmount(amount);
        if (isNaN(amountNum) || !amountNum) {
            return;
        }
        let maskedVal: string = this.decimalPipe.transform(amountNum);
        if (amount !== maskedVal) {
            const trailingDivider = amount?.toString().slice(-1);
            if ([",", "."].includes(trailingDivider)) {
                maskedVal += trailingDivider;
            }
            this.payoutForm.get("amount").patchValue(maskedVal, {emitEvent: false});
        }
        this.amount$.next(amountNum);
    }

    private static fmtCardCommissionText(debitRates: BillingRates["debitRates"]): string {
        if (_.isNil(debitRates)) {
            return "";
        }

        const cardRates = debitRates[PayoutMethodType.CARD];
        if (_.isNil(cardRates)) {
            return "";
        }

        const percent = cardRates[0]?.percent;
        const firstText = percent
            ? `на карту: ${percent}%`
            : `на карту: ${cardRates[0]?.fixed ?? 0}₽`;
        const secondRate = cardRates[1];
        const secondText = secondRate
            ? ` или ${secondRate.fixed}₽<br>при выводе 10 000₽ и более`
            : "";

        return `${firstText} ${secondText}`;
    }

    private static parseAmount(amount) {
        if (typeof amount === "string") {
            return +(amount.replace(/,/g, ".").replace(/\s/g, ""));
        } else {
            return amount;
        }
    }
}
