import {Injectable, OnDestroy} from "@angular/core";
import {combineLatest, Observable, ReplaySubject, Subject} from "rxjs";
import {filter, map, shareReplay, takeUntil} from "rxjs/operators";
import {SSEService} from "../sse.service";
import {
    AccountStatistics,
    AccountStatisticsMonth,
    BillingLimitsUpdatedEvent,
    BillingSystemType,
    HasBalance,
    VerificationState
} from "../../../../../shared/src/lib/models/finance.api";
import {
    AccountBillingSystemEvent,
    BillingRates, FinanceEventType, OrderRemovedEvent, OrderStateEvent, RatesUpdateEvent, VerificationEvent
} from "../../../../../shared/src/lib/common/FinancialOperation";
import {AccountLimits, makeAccountLimits} from "./account-limits";

@Injectable({
    providedIn: "root"
})
export class FinanceSSEService implements OnDestroy {

    public readonly balance$ = new ReplaySubject<number>(1);
    public readonly limits$ = new ReplaySubject<AccountLimits>(1);
    public readonly rates$ = new ReplaySubject<BillingRates>(1);
    public readonly statistics$ = new ReplaySubject<AccountStatistics>(1);
    public readonly verificationState$ = new ReplaySubject<VerificationState>(1);
    public readonly billingSystemType$ = new ReplaySubject<BillingSystemType>(1);
    public readonly orderRemoved$ = new Subject<string>();
    public readonly orderState$ = new Subject<OrderStateEvent>();

    public readonly balanceLimits$ =
        combineLatest([this.balance$, this.limits$]).pipe(shareReplay(1));

    public readonly usageTotal$: Observable<number> = this.statistics$.pipe(
        map(statistics => statistics.months[0].donations.total),
        shareReplay(1));

    public readonly usageCurrent$: Observable<number> = this.statistics$.pipe(
        map(statistics => statistics.months[0].donations.current),
        shareReplay(1));

    private readonly destroy$: Subject<void> = new Subject();
    private readonly sseFinanceMessage$ = this.sseService.message$.pipe(
        filter(m => !!this.handlers[m.type]),
        takeUntil(this.destroy$));

    public constructor(private readonly sseService: SSEService) {
        this.sseFinanceMessage$.subscribe(m => this.handlers[m.type](m.event));
    }

    public ngOnDestroy() {
        this.destroy$.next();
    }

    private readonly handlers: { [key in FinanceEventType]: (event: object) => void } = {
        [FinanceEventType.Balance]: (event: HasBalance) => {
            this.balance$.next(event.balance);
        },
        [FinanceEventType.Limits]: (event: BillingLimitsUpdatedEvent) => {
            this.limits$.next(makeAccountLimits(event));
        },
        [FinanceEventType.Statistics]: (event: AccountStatistics) => {
            const compareDescending =
                (l: AccountStatisticsMonth, r: AccountStatisticsMonth) =>
                    new Date(r.date).getTime() - new Date(l.date).getTime();

            const monthsRaw = [...event.months];
            const monthsDescending = [...monthsRaw].sort(compareDescending);
            console.log(
                `${FinanceEventType.Statistics}: raw=`, monthsRaw,
                "desc=", monthsDescending);

            event.months = [...monthsDescending];
            this.statistics$.next(event);
        },
        [FinanceEventType.AccountVerificationState]: (event: VerificationEvent) => {
            this.verificationState$.next(event.verification);
        },
        [FinanceEventType.AccountBillingSystem]: (event: AccountBillingSystemEvent) => {
            this.billingSystemType$.next(event.type);
        },
        [FinanceEventType.OrderRemoved]: (event: OrderRemovedEvent) => {
            this.orderRemoved$.next(event.id);
        },
        [FinanceEventType.OrderStateUpdate]: (event: OrderStateEvent) => {
            this.orderState$.next(event);
        },
        [FinanceEventType.RatesUpdate]: (event: RatesUpdateEvent) => {
            this.rates$.next(event);
        },
    };
}
