<section *ngIf="statistics$ | async as statistics else placeholder">
    <header>
        <h5 class="white">Донаты</h5>
        <span>(за три последних месяца)</span>
    </header>
    <div *ngFor="let item of statistics.months | slice:0:3">
        <span>{{item.date | date:'LLLL' | titlecase }}</span>
        <span>{{item.donations.total | currency:currency:'symbol':'1.0'}}</span>
    </div>
    <footer class="white">
        <span>Всё время</span>
        <span>{{statistics.total.donations | currency:currency:'symbol':'1.0'}}</span>
    </footer>
</section>

<ng-template #placeholder>
    <section id="placeholder">
        <header><h5></h5></header>
        <div>
            <span></span>
            <span></span>
        </div>
        <div>
            <span></span>
            <span></span>
        </div>
        <div>
            <span></span>
            <span></span>
        </div>
        <footer>
            <span></span>
            <span></span>
        </footer>
    </section>
</ng-template>
