import {BillingSystemType, PaymentMethod, PaymentMethodType, VerificationState} from "../../models/finance.api";

export enum Currency {
    RUB = "RUB",
}

export enum TransactionState {
    PENDING = "PENDING",
    EXECUTING = "EXECUTING",
    ERROR = "ERROR",
    SUCCESS = "SUCCESS",
}

export enum TransactionType {
    PAYMENT_APPLE = "PAYMENT_APPLE",
    PAYMENT_CARD = "PAYMENT_CARD",
    PAYMENT_GOOGLE = "PAYMENT_GOOGLE",
}

export interface Transaction {
    refId: string;
    type: TransactionType;
    state: TransactionState;
    amount: number;
    createdAt: Date;
    startedAt?: Date;
    stoppedAt?: Date;
    error?: any;
    attributes?: any;
}

export interface PayoutMethod {
    id: string;
    billingSystemType: string;
    displayName: string;
    type: PayoutMethodType;
    commission: number;
    isNew: boolean;
}

export enum PayoutMethodType {
    YANDEX = "YANDEX",
    QIWI = "QIWI",
    CARD = "CARD"
}

export interface FinancialOperation {
    amount: number;
    payee?: { number?: string, refId?: string };
    refId?: string;
    status?: string;
    commission?: number;
    cardNumber?: string;
    transactions?: [{
        type: string,
        refId: string,
        state: string,
        paymentUrl?: string,
    }];
}

export interface PayoutMethodsResponse {
    methods: Array<PayoutMethod>;
}

export interface CreateUserIdentityVerificationRequest {
    type: OrderType.UserIdentityVerification;
    args: {
        userIdentityVerification: {
            manual: {
                aboutMe?: string
                channel: string
                contacts?: string
            }
        }
    }
}

export interface CreateUserIdentityVerificationResponse {
    refId: string;
}

export interface CreateWithdrawalRequest {
    type: OrderType.Withdrawal;
    args: {
        withdrawal: {
            amount: number;
            method: {
                id: string;
                args?: {
                    account: string;
                }
            }
        }
    };
}

export interface CreateWithdrawalResponse {
    refId: string;
}

export interface SaveWithdrawalMethodRequest {
    orderId: string;
}

export interface PaymentMethodsResponse {
    methods: Array<PaymentMethod>;
}

export enum OrderType {
    Balance = "BALANCE",
    Payment = "PAYMENT",
    UserIdentityVerification = "USER_IDENTITY_VERIFICATION",
    Withdrawal = "WITHDRAWAL",
}

export enum OrderState {
    Created = "CREATED",
    Initial = "INITIAL",
    OrderPlaced = "IN_PROGRESS/PLACE_CONCRETE_ORDER",
    FormReady = "IN_PROGRESS/PAYMENT_FORM_READY",
    ReadyForWithdrawal = "IN_PROGRESS/READY_FOR_WITHDRAW",
    ReadyForWithdrawalSuspicious = "IN_PROGRESS/FRAUD_SCREENING/SUSPICIOUS",
    Withdraw = "IN_PROGRESS/WITHDRAW",
    SubOrdersPlaced = "IN_PROGRESS/PLACE_SUB_ORDERS",
    CalculateSuspendedWithdrawals = "IN_PROGRESS/CALCULATE_SUSPENDED_WITHDRAWALS",
    UserIdentityVerificationInProgress = "IN_PROGRESS/VERIFYING",
    Completed = "COMPLETED",
    Failed = "FAILED",
}

export enum FinanceEventType {
    Balance = "BILLING/BALANCE/UPDATE",
    Limits = "BILLING/LIMITS/UPDATE",
    Statistics = "BILLING/STATISTICS/UPDATE",
    AccountVerificationState = "BILLING/ACCOUNT/STATE/UPDATE",
    AccountBillingSystem = "BILLING/ACCOUNT/SYSTEM/UPDATE",
    OrderRemoved = "BILLING/ORDER/REMOVED",
    OrderStateUpdate = "BILLING/ORDER/STATE/UPDATE",
    RatesUpdate = "BILLING/RATES/UPDATE",
}

export enum AccountEventType {
    AddSocial = "ADD_SOCIAL",
    RemoveSocial = "REMOVE_SOCIAL",
    ReconnectSocial = "RECONNECT_SOCIAL",
}

export enum WidgetEventType {
    Remove = "WIDGETS/REMOVE",
    Add = "WIDGETS/ADD",
    Update = "WIDGETS/SETTINGS/UPDATE",
    Live = "WIDGETS/ONLINE/UPDATE",
    GroupAdd = "WIDGETS/GROUP/ADD",
}

export enum GeneralEventType {
    AccessDenied = "ACCESS_DENIED",
}

export enum StreamEventType {
    StreamEvent = "STREAM_EVENT",
}

export interface PutOrderStateRequest {
    state: OrderState;
}

export interface VerificationEvent {
    verification: VerificationState;
}

export interface OrderRemovedEvent {
    id: string;
}

export interface AccountBillingSystemEvent {
    type: BillingSystemType;
}

// tslint:disable-next-line:no-empty-interface
export interface SSEState {
}

export interface UserIdentityVerificationState extends SSEState {
    userIdentityVerification: {

    }
}

export interface WithdrawalState extends SSEState {
    withdrawal: {
        amount: number;
        method: {
            id: string;
            args: {
                account: string;
            },
            displayName: string;
        };
        paymentForm: {
            url: string;
        };
    };
}

export interface PaymentState extends SSEState {
    payment: {
        amount: number;
        method: {
            type: PaymentMethodType;
        };
        paymentForm: {
            url: string;
        };
    };
}

export interface OrderStateEvent<T extends SSEState = SSEState> {
    data: T;
    orderType: OrderType;
    refId: string;
    state: OrderState;
}

export type BillingRates = {
    creditRates?: {
        [key in PaymentMethodType]:
            Array<{ from: number, percent?: number, fixed?: number }>
    };

    debitRates?: {
        [key in PayoutMethodType]:
            Array<{ from: number, percent?: number, fixed?: number }>
    };
}

export interface RatesUpdateEvent extends BillingRates {
}
