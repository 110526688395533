import {NgModule} from "@angular/core";
import {Routes, RouterModule} from "@angular/router";
import {LoginComponent} from "./components/login/login.component";
import {FinanceComponent} from "./components/finance/finance.component";
import {DonateEditComponent} from "./components/donate/donate-edit.component";
import {IdentityComponent} from "./components/identity/identity.component";
import {IdentityV2Component} from "./components/identity/v2/identity-v2.component";
import {IdentityThanksComponent} from "./components/identity/thanks/identity.thanks.component";
import {WidgetsComponent} from "./components/widgets/widgets.component";
import {WidgetEditComponent} from "./components/widgets/edit/widget-edit.component";
import {PaymentProcessingComponent} from "./components/finance/payout/payout-processing/payment-processing.component";
import {PayoutErrorComponent} from "./components/finance/payout/payout-error/payout-error.component";
import {MobileFrontComponent} from "./components/mobile-version/mobile-front/mobile-front.component";
import {StreamerProfileComponent} from "./components/streamer-profile/streamer-profile.component";
import {AuthGuard} from "./guards/auth.guard";
import {DesktopGuard} from "./guards/desktop.guard";
import {GuestGuard} from "./guards/guest.guard";
import {IdentityMonetaTransitComponent} from "./components/identity/moneta-transit/identity-moneta-transit.component";

const routes: Routes = [
    { path: "", pathMatch: "full", redirectTo: "finance" },
    {
        path: "finance", pathMatch: "full",
        component: FinanceComponent, canActivate: [AuthGuard, DesktopGuard],
        data: { reuse: "finance", animation: "finance" }
    },
    { path: "login", component: LoginComponent, canActivate: [GuestGuard], data: {animation: "login"} },
    { path: "login/complete", component: LoginComponent, canActivate: [GuestGuard], data: {animation: "login/complete"} },
    { path: "mobile", component: MobileFrontComponent, canActivate: [AuthGuard] },
    {
        path: "profile",
        component: StreamerProfileComponent, canActivate: [AuthGuard, DesktopGuard],
        data: { reuse: "profile", animation: "profile" },
    },
    {
        path: "widgets",
        component: WidgetsComponent, canActivate: [AuthGuard, DesktopGuard],
        data: { reuse: "widgets", animation: "widgets" },
    },
    {
        path: "widgets/donate",
        component: DonateEditComponent, canActivate: [AuthGuard, DesktopGuard],
        data: { reuse: "donate", animation: "donate" },
    },
    {
        path: "widgets/:id/edit",
        component: WidgetEditComponent, canActivate: [AuthGuard, DesktopGuard],
        data: { animation: "widget" },
    },
    {
        path: "identity",
        component: IdentityComponent, canActivate: [AuthGuard],
        data: { animation: "identity" },
    },
    {
        path: "identity/v2",
        component: IdentityV2Component, canActivate: [AuthGuard],
        data: { animation: "identity" },
    },
    {
        path: "identity/moneta_transit",
        component: IdentityMonetaTransitComponent, canActivate: [AuthGuard],
        data: { animation: "identity" },
    },
    {
        path: "identity/thanks",
        component: IdentityThanksComponent, canActivate: [AuthGuard],
        data: { animation: "identity-thanks" },
    },
    {path: "payout/confirm", component: PaymentProcessingComponent, canActivate: [AuthGuard]},
    {path: "payout/error", component: PayoutErrorComponent, canActivate: [AuthGuard]},
    {path: "**", redirectTo: ""}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
