<div class="perspective">
    <div class="balance"
         *ngIf="limits$ | async as limits else loader"
         [ngClass]="{'flipped': flipped$ | async}">
        <div class="front">
            <header>
                <h5 class="white">Баланс</h5>
                <div class="rectangle"
                     *ngIf="(payoutAvailable$ | async) && (hasLimits$ | async)"
                     (click)="flipped$.next(!flipped$.value)">
                    <mat-icon>chevron_right</mat-icon>
                    Лимиты
                </div>
            </header>
            <section class="amount" [ngClass]="{'error': (isAnyLimitReached$ | async) }">
                <span>{{(financeSSEService.balance$ | async) || 0 | number:'1.0-0':'ru'}} </span>
                <span class="currency">₽</span>
            </section>
            <section class="progress" [ngClass]="{'error': (isAnyLimitReached$ | async) }">
                <ng-container *ngIf="limitProgress$ | async as limitProgress">
                    <ng-container *ngIf="!(isMonthlyLimitReached$ | async) else monthlyLimitReached">
                        Максимальный баланс
                        {{limits.current.balance.max | currency:currency:'symbol':'1.0'}}
                    </ng-container>
                    <ng-template #monthlyLimitReached>
                        Лимит на сумму донатов в месяц
                        {{limits.current.credit.month | currency:currency:'symbol':'1.0'}}
                    </ng-template>
                    <mat-progress-bar mode="determinate" [value]="limitProgress"
                                      [style.--gradient]="progressGradient$ | async"
                                      [ngClass]="{'error': (financeSSEService.balance$ | async) || 0 >= limits.current.balance.max}"></mat-progress-bar>
                </ng-container>
            </section>
            <section class="actions" *ngIf="verificationState$ | async as verificationState">
                <button *ngIf="payoutAvailable$ | async else identify"
                        [disabled]="isWithdrawalDisabled$ | async"
                        (click)="financeService.openPayout$.next()"
                        mat-flat-button type="submit">
                    <mat-icon svgIcon="card"></mat-icon>
                    Вывести средства
                </button>
                <ng-template #identify>
                    <button [disabled]="!(userService.permissions$ | async) || verificationState === VerificationState.VERIFYING"
                            [ngSwitch]="verificationState"
                            [routerLink]="identityLink$ | async"
                            mat-flat-button type="submit">
                        <ng-container *ngSwitchCase="VerificationState.VERIFYING">
                            <mat-icon svgIcon="analyse"></mat-icon>
                            Идентификация...
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <mat-icon svgIcon="shield"></mat-icon>
                            Идентифицировать
                        </ng-container>
                    </button>
                </ng-template>
            </section>
        </div>
        <div class="back">
            <header>
                <h5 class="white">Лимиты</h5>
                <div class="rectangle"
                     *ngIf="payoutAvailable$ | async"
                     (click)="flipped$.next(!flipped$.value)">
                    Баланс
                    <mat-icon>chevron_left</mat-icon>
                </div>
            </header>
            <div class="sections">
                <section *ngIf="limits.current.credit.month">
                    <div>
                        <div class="title">Общая сумма донатов</div>
                        <div class="subtitle">в месяц</div>
                    </div>
                    <div class="amount">
                        <h4 [ngClass]="{'error': isMonthlyLimitReached$ | async }">
                            {{limits.current.credit.month | currency:currency:'symbol':'1.0'}}
                        </h4>
                        <mat-progress-bar mode="determinate" [value]="monthLimitProgress$ | async"
                                          [style.--gradient]="monthLimitProgressGradient$ | async"
                                          [ngClass]="{'error':
                                            ((financeSSEService.usageCurrent$ | async) ?? 0) >=
                                                limits.current.credit.month}">
                        </mat-progress-bar>
                    </div>
                </section>
                <section *ngIf="limits.current.balance.max">
                    <div>
                        <div class="title">Макс. доступный остаток</div>
                        <div class="subtitle">на счёте</div>
                    </div>
                    <div class="amount">
                        <h4 [ngClass]="{'error': isAccountLimitReached$ | async }">
                            {{limits.current.balance.max | currency:currency:'symbol':'1.0'}}
                        </h4>
                        <mat-progress-bar mode="determinate" [value]="limitProgress$ | async"
                                          [style.--gradient]="progressGradient$ | async"
                                          [ngClass]="{'error': (financeSSEService.balance$ | async) || 0 >= limits.current.balance.max}"></mat-progress-bar>
                    </div>
                </section>
                <section *ngIf="limits.current.credit.max">
                    <div>
                        <div class="title">Макс. размер доната</div>
                        <div class="subtitle">единовременно</div>
                    </div>
                    <div class="amount">
                        <div>
                            <h4>{{limits.current.credit.max | currency:currency:'symbol':'1.0'}}</h4>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>

<div class="hint" *ngIf="hint$ | async as hint" [ngSwitch]="hint">
    <ng-container *ngSwitchCase="BalanceHint.InProgress">
        <mat-icon svgIcon="analyse"></mat-icon>
        <span>
            Идёт процесс идентификации
        </span>
    </ng-container>
    <ng-container *ngSwitchCase="BalanceHint.Failure">
        <mat-icon class="error">error_outline</mat-icon>
        <span>
            Не удалось пройти идентификацию. Возможно, виновата&nbsp;опечатка. Пожалуйста,
            <a routerLink="/identity">попробуйте снова</a>
        </span>
    </ng-container>
    <ng-container *ngSwitchCase="BalanceHint.Promote">
        <span>
            Чтобы выводить деньги, нужно пройти процедуру упрощенной идентификации. Это требование закона
            <span class="white">🤷‍♂️️</span>
        </span>
    </ng-container>
</div>
<ng-template #loader>
    <div id="loader" class="balance">
        <div class="front">
            <header>
                <h5></h5>
            </header>
            <section class="amount">
                <span>&nbsp;</span>
            </section>
            <section class="progress"></section>
            <section class="actions">
                <div>&nbsp;</div>
            </section>
        </div>
    </div>
</ng-template>
