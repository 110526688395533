<div class="payout-wrapper" [ngSwitch]="state">
    <ng-template *ngSwitchCase="'inProgress'" [ngTemplateOutlet]="inProgress"></ng-template>
    <ng-template *ngSwitchCase="'success'" [ngTemplateOutlet]="success"
                 [ngTemplateOutletContext]="{ $implicit: proposeToSaveWithdrawalMethod$ | async }"></ng-template>
    <ng-template *ngSwitchCase="'failed'" [ngTemplateOutlet]="failed"></ng-template>
</div>
<ng-template #inProgress>
    <h1>Вывод денег</h1>
    <form dense [formGroup]="payoutForm">
        <mat-stepper #stepper orientation="vertical" linear [disableRipple]="true">
            <mat-step [stepControl]="firstStepControl" state="one">
                <ng-template matStepLabel>
                    <h6>Сумма</h6><span [innerHTML]="amount$ | async | currency:currency"></span>
                </ng-template>
                <ng-template matStepContent>
                    <mat-form-field id="balance" appearance="outline" [hideRequiredMarker]="true"
                                    *ngIf="financeSSEService.limits$ | async as limits">
                        <mat-label>
                            Баланс:
                            <span
                                [innerHTML]="financeSSEService.balance$ | async | currency:currency:'symbol':'1.2'"></span>
                        </mat-label>
                        <input matInput [errorStateMatcher]="showAlwaysMatcher" [maxlength]="amountMaxLength$ | async"
                               formControlName="amount" required>
                        <span matSuffix>₽</span>
                        <mat-error *ngIf="payoutForm.get('amount').hasError('required') else otherErrors">
                            Ошибка ввода.
                            Минимум <span [innerHTML]="limits.current.debit.min | currency:currency:'symbol':'1.0'"></span>,
                            максимум <span [innerHTML]="limits.current.debit.max | currency:currency:'symbol':'1.0'"></span>
                        </mat-error>
                        <ng-template #otherErrors>
                            <mat-error *ngIf="payoutForm.get('amount').hasError('tariffMin')">
                                Минимум <span [innerHTML]="limits.current.debit.min | currency:currency:'symbol':'1.0'"></span>,
                                максимум <span [innerHTML]="limits.current.debit.max | currency:currency:'symbol':'1.0'"></span>
                            </mat-error>
                            <mat-error *ngIf="payoutForm.get('amount').hasError('tariffMax')">
                                Максимальная сумма вывода
                                <span [innerHTML]="limits.current.debit.max | currency:currency:'symbol':'1.0'"></span>
                            </mat-error>
                            <mat-error *ngIf="payoutForm.get('amount').hasError('balanceMax')">
                                На балансе недостаточно средств
                            </mat-error>
                        </ng-template>
                    </mat-form-field>
                    <button mat-flat-button color="primary"
                            [disabled]="payoutForm.get('amount').invalid"
                            (click)="firstStepControl.get('lock').updateValueAndValidity(); stepper.next();">
                        Продолжить
                    </button>
                </ng-template>
            </mat-step>
            <mat-step [stepControl]="operationForm" formGroupName="operation" state="two">
                <ng-template matStepLabel>
                    <h6>{{labels.stepHeader[payoutMethod?.type] || "Способ вывода"}}</h6>
                    <span class="account-number" *ngIf="withdrawalReady$ | async as withdrawal">
                        {{formatDisplayName(withdrawal.data.withdrawal.method.displayName, withdrawal.data.withdrawal.method.id) | async}}
                    </span>
                </ng-template>
                <ng-template matStepContent>
                    <mat-radio-group formControlName="method">
                        <mat-radio-button *ngFor="let item of withdrawalMethods$ | async"
                                          [value]="item.id"
                                          [disableRipple]="false"
                                          color="primary">
                            <div class="info">
                                <ng-container *ngIf="item.isNew else displayNameFormat">
                                    {{item.displayName}}
                                </ng-container>
                                <ng-template #displayNameFormat>
                                    {{labels.savedMethodType[item.type]}} ****&nbsp;{{item.displayName | slice:-4}}
                                </ng-template>
                                <span class="delete" *ngIf="!item.isNew">(<a (click)="onDeleteMethod(item)">удалить</a>)</span>
                                <span class="commission" *ngIf="commission$ | async as commission">
                                    {{-item.commission | currency:currency:'symbol':'1.2'}}
                                    <span (mouseenter)="infoTooltip.show($event.target)"
                                          (mouseleave)="infoTooltip.hide()">(<span>?</span>)</span>
                                    <lib-tooltip #infoTooltip [position]="'bottom-left'">
                                        <div class="tootlip">
                                            Комиссия при выводе<br>
                                            <span [innerHTML]="commission[item.type]"></span>
                                        </div>
                                    </lib-tooltip>
                                </span>
                            </div>
                            <form [ngSwitch]="item.type" *ngIf="payoutMethod?.id === item.id">
                                <app-payout-card *ngSwitchCase="'CARD'"
                                                 [amount]="amount$ | async"
                                                 [method]="payoutMethod"
                                                 (order$)="withdrawalOrder$.next($event)">
                                </app-payout-card>
                                <app-payout-yandex *ngSwitchCase="'YANDEX'"
                                                   [amount]="amount$ | async"
                                                   [method]="payoutMethod"
                                                   (order$)="withdrawalOrder$.next($event)">
                                </app-payout-yandex>
                                <app-payout-qiwi *ngSwitchCase="'QIWI'"
                                                 [amount]="amount$ | async"
                                                 [method]="payoutMethod"
                                                 (order$)="withdrawalOrder$.next($event)">
                                </app-payout-qiwi>
                            </form>
                        </mat-radio-button>
                    </mat-radio-group>
                </ng-template>
            </mat-step>
            <mat-step state="three">
                <ng-template matStepLabel><h6>Подтверждение</h6></ng-template>
                <ng-template matStepContent>
                    <mat-card [class.mat-elevation-z]="true">
                        <div>
                            <div>{{labels.payoutMethodTitle[payoutMethod.type]}}</div>
                            <div *ngIf="withdrawalReady$ | async as withdrawal">
                                {{ formatDisplayName(
                                    withdrawal.data.withdrawal.method.displayName
                                        ?? "Неизвестно",
                                    withdrawal.data.withdrawal.method.id) | async }}
                            </div>
                        </div>
                        <div>
                            <div>Сумма</div>
                            <div [innerHTML]="amount$ | async | currency:currency:'symbol':'1.2'"></div>
                        </div>
                        <div>
                            <div>Комиссия</div>
                            <div [innerHTML]="payoutMethod.commission | currency:currency:'symbol':'1.2'"></div>
                        </div>
                        <div class="total">
                            <div>К выводу</div>
                            <div [innerHTML]="(((amount$ | async) - payoutMethod.commission)) | currency:currency:'symbol':'1.2'"></div>
                        </div>
                    </mat-card>
                    <button mat-flat-button color="primary"
                            (click)="withdraw$.next()"
                            [disabled]="finishing">
                        {{labels.payoutButtonTitle[payoutMethod?.type]}}
                    </button>
                </ng-template>
            </mat-step>
            <ng-template matStepperIcon="one">
                <mat-icon svgIcon="1"></mat-icon>
            </ng-template>
            <ng-template matStepperIcon="two">
                <mat-icon svgIcon="2"></mat-icon>
            </ng-template>
            <ng-template matStepperIcon="three">
                <mat-icon svgIcon="3"></mat-icon>
            </ng-template>
            <ng-template matStepperIcon="check">
                <mat-icon svgIcon="check"></mat-icon>
            </ng-template>
            <ng-template matStepperIcon="edit" let-index=index>
                <mat-icon svgIcon="{{index + 1}}"></mat-icon>
            </ng-template>
        </mat-stepper>
    </form>
</ng-template>

<ng-template #success let-propose>
    <div class="finish">
        <figure>
            <img alt="Payment success" src="/assets/icons/payment-success.png">
            <img alt="" class="glow" src="/assets/icons/payment-success.png">
        </figure>
        <h1>Успех!</h1>
        <form *ngIf="propose">
            <div class="light-gray">
                Сохранить этот способ вывода денег, чтобы в
                следующий раз не&nbsp;вводить реквизиты заново?
            </div>
            <div class="actions">
                <button mat-flat-button color="primary"
                        (click)="saveWithdrawalMethod$.next(); financeService.closePayout$.next()">
                    Сохранить
                </button>
                <button mat-flat-button (click)="financeService.closePayout$.next()">
                    Не сохранять
                </button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #failed>
    <div class="finish">
        <figure>
            <img alt="Payment success" src="/assets/icons/payment-failed.png">
            <img alt="" class="glow" src="/assets/icons/payment-failed.png">
        </figure>
        <h1>Ошибка!</h1>
        <div class="light-gray">
            Что-то пошло не так. Попробуйте снова или напишите
            в поддержку и мы обязательно во всем разберемся <span class="white">😇</span>
        </div>
        <div class="actions">
            <button mat-flat-button color="primary"
                    class="support-call"
                    onclick="carrotquest && carrotquest.open()">
                Поддержка
            </button>
            <button mat-flat-button (click)="retry()">
                Попробовать снова
            </button>
        </div>
    </div>
</ng-template>

<lib-confirmation-modal #cardDeleteDialog
                        [header]="labels.deleteDlgHeader[withdrawalMethodToDelete$.value?.type]"
                        [confirmText]="labels.deleteDlgConfirmText[withdrawalMethodToDelete$.value?.type]"
                        cancelText="Отмена"
                        (confirm)="deleteMethod(withdrawalMethodToDelete$.value)"
                        (cancel)="withdrawalMethodToDelete$.next(null)">
    <div id="confirm-dialog">
        Вы действительно хотите удалить
        {{labels.deleteDlgPayoutMethod[withdrawalMethodToDelete$.value?.type]}}
        <ng-container *ngIf="withdrawalMethodToDelete$ | async as method">
            ****&nbsp;{{method.displayName | slice:-4}}?
        </ng-container>
    </div>
</lib-confirmation-modal>
