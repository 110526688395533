import {BillingLimitsUpdatedEvent} from "../../../../../shared/src/lib/models/finance.api";

export enum LimitsId {
    MonetaLegacyAnonymous = "MONETA_LEGACY/ANONYMOUS",
    MonetaLegacyStandard = "MONETA_LEGACY/STANDARD",
    MonetaTransitBasic = "MONETA_TRANSIT/basic",
}

export interface Limits {
    balance: { max?: number };
    credit?: { min?: number, max?: number, month?: number };
    debit?: { min?: number, max?: number, month?: number };
}

export interface BillingLimits extends Limits{
    id: string;
}

export interface LimitsSet {
    [id: string]: Limits;
}

export interface AccountLimits {
    current: BillingLimits;
    all: LimitsSet;

    findById(id: LimitsId): BillingLimits;
}

export function makeAccountLimits(event: BillingLimitsUpdatedEvent): AccountLimits {
    const findById = (id: string) =>
        event.limits.find(t => (t.id === id))
            ?? UNKNOWN_LIMITS;

    const current = findById(event.currentId);

    const all: LimitsSet = {};
    event.limits.forEach(l =>
        all[l.id] = {
            balance: l.balance,
            credit: l.credit,
            debit: l.debit
        });

    return {
        current,
        all,
        findById
    };
}

const UNKNOWN_LIMITS: BillingLimits = {
    id: "UNKNOWN",
    balance: {
        max: 0
    },
    credit: {
        min: 0,
        max: 0,
        month: 0
    },
    debit: {
        min: 0,
        max: 0,
        month: 0
    }
};
