import {ChangeDetectionStrategy, Component} from "@angular/core";
import {combineLatest, filter, map, tap, withLatestFrom} from "rxjs/operators";
import {combineLatestWith, Observable} from "rxjs";
import {FinanceSSEService} from "../../../services/finance/finance-sse.service";
import {Currency} from "../../../../../../shared/src/lib/common/FinancialOperation";
import {AccountLimits, LimitsId} from "../../../services/finance/account-limits";
import {BillingSystemType} from "../../../../../../shared/src/lib/models/finance.api";

interface Item {
    title: string;
    subtitle: string;
    amount: number;
}

@Component({
    selector: "app-identity-info",
    templateUrl: "./identity-info.component.html",
    styleUrls: ["./identity-info.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdentityInfoComponent {
    public readonly items$: Observable<Array<Item>> = this.financeSSEService.limits$.pipe(
        combineLatestWith(this.financeSSEService.billingSystemType$),
        map(([limits, system]: [AccountLimits, BillingSystemType]) =>
            limits ? IdentityInfoComponent.formatLimitItems(limits, system) : null
        ),
        filter<Array<Item>>(Boolean));

    public readonly currency = Currency.RUB;

    constructor(public readonly financeSSEService: FinanceSSEService) {
    }

    private static formatLimitItems(
        limits: AccountLimits,
        system: BillingSystemType
    ): Array<Item> {
        const verifiedTariff = VERIFIED_TARIFFS[system];
        const { credit, balance } = limits.findById(verifiedTariff);

        return [
            {
                title: "Общая сумма донатов",
                subtitle: "в месяц",
                amount: credit.month,
            },
            {
                title: "Макс. доступный остаток",
                subtitle: "на счёте",
                amount: balance.max,
            },
            {
                title: "Макс. размер доната",
                subtitle: "единовременно",
                amount: credit.max,
            }
        ];
    }
}

const VERIFIED_TARIFFS =  {
    [BillingSystemType.MonetaLegacy]: LimitsId.MonetaLegacyStandard,
    [BillingSystemType.MonetaTransit]: LimitsId.MonetaTransitBasic,
};