import {Injectable, OnDestroy} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {combineLatest, iif, Observable, of, Subject, Subscription, timer} from "rxjs";
import {BaseService} from "../base.service";
import {AuthService} from "../../../../../shared/src/lib/auth.service";
import {DevSettingsService} from "../../../../../shared/src/lib/dev/dev-settings.service";
import {EnvironmentService} from "../../../../../shared/src/lib/environment.service";
import {
    filter, map, mergeMap, startWith, switchMap, switchMapTo, takeUntil,
} from "rxjs/operators";
import {environment} from "../../../environments/environment";
import {
    IdentityRequest, VerificationState, Wallet,
} from "../../../../../shared/src/lib/models/finance.api";
import {MonthItem} from "../donation/donation.api";
import {SidebarService} from "../../../../../shared/src/lib/sidebar/sidebar.service";
import {PayoutComponent} from "../../components/finance/payout/payout.component";
import {ApiListResponse} from "../../../../../shared/src/lib/common";
import {
    CreateUserIdentityVerificationRequest, CreateUserIdentityVerificationResponse,
    CreateWithdrawalRequest,
    CreateWithdrawalResponse,
    OrderState,
    OrderStateEvent,
    OrderType,
    PayoutMethod,
    PayoutMethodsResponse,
    PayoutMethodType,
    PutOrderStateRequest,
    SaveWithdrawalMethodRequest,
    SSEState,
    UserIdentityVerificationState,
    WithdrawalState,
} from "../../../../../shared/src/lib/common/FinancialOperation";
import {DataLayerService} from "../../../../../shared/src/lib/data-layer.service";
import {EventAction, EventCategory} from "../../../../../shared/src/lib/models/analytics-events";
import {FinanceSSEService} from "./finance-sse.service";
import {JsonErrorObject} from "../../../../../shared/src/lib/models/json-error-object";

const noVerificationStates = [VerificationState.VERIFIED, VerificationState.VERIFYING, VerificationState.NOT_APPLICABLE];

@Injectable({
    providedIn: "root"
})
export class FinanceService extends BaseService implements OnDestroy {
    public readonly openPayout$: Subject<void> = new Subject();
    public readonly closePayout$: Subject<void> = new Subject();

    private getStatisticsRequest: Subscription;
    private confirmPaymentRequest: Subscription;
    private readonly baseUrl: string;
    private readonly billingUrl: string;

    public readonly promoteVerification$ = this.financeSSEService.verificationState$.pipe(
        map(verificationState => !noVerificationStates.includes(verificationState)),
        startWith(false),
    );

    public readonly verificationSuccess$ = this.financeSSEService.verificationState$.pipe(
        filter<VerificationState>(Boolean),
        filter(verificationState => verificationState !== VerificationState.VERIFIED),
        switchMap(() => this.financeSSEService.verificationState$.pipe(
            filter(verificationState => verificationState === VerificationState.VERIFIED))),
    );
    public readonly verificationError$ = this.financeSSEService.verificationState$.pipe(
        filter<VerificationState>(Boolean),
        filter(verificationState => verificationState !== VerificationState.FAILURE),
        switchMap(() => this.financeSSEService.verificationState$.pipe(
            filter(verificationState => verificationState === VerificationState.FAILURE))),
    );

    private readonly destroy$: Subject<void> = new Subject();

    constructor(authService: AuthService,
                private readonly httpClient: HttpClient,
                private readonly financeSSEService: FinanceSSEService,
                private readonly devSettingsService: DevSettingsService,
                private readonly environmentService: EnvironmentService,
                private readonly sidebar: SidebarService,
                private readonly dataLayerService: DataLayerService) {
        super(authService, httpClient);
        this.serviceUrl = "finance";
        this.baseUrl = `${environment.donationAPI}/finance`;
        this.billingUrl = `${environment.donationAPI}/billing/v2`;

        this.openPayout$.subscribe(() => this.sidebar.open(PayoutComponent));
        this.closePayout$.subscribe(() => this.sidebar.close());
        this.initDataLayer();
    }

    public ngOnDestroy() {
        this.destroy$.next();
    }

    async getStatistics(aggregateBy: "DAY" | "MONTH", startFrom?: Date, endTo?: Date, utcOffset?: number): Promise<Array<MonthItem>> {
        const start = startFrom ? startFrom.toISOString() : "";
        const end = endTo ? endTo.toISOString() : "";
        const zoneOffset = utcOffset ? utcOffset : startFrom.getTimezoneOffset();
        const resp = await this.getRequest(`statistics?aggregateBy=${aggregateBy}&startFrom=${start}&endTo=${end}&zoneOffset=${zoneOffset}`,
            this.getStatisticsRequest);
        return resp.items;
    }

    public getWithdrawalMethods(amount: number): Observable<Array<PayoutMethod>> {
        if (!amount) {
            return of([]);
        }
        const url = `${this.billingUrl}/withdrawal/methods`;
        const params = new HttpParams().set("amount", "" + amount);
        const order = [PayoutMethodType.YANDEX, PayoutMethodType.QIWI, PayoutMethodType.CARD];
        return this.http.get<PayoutMethodsResponse>(url, {...this.authService.makeTokenAuthHeaders(), params}).pipe(
            map<PayoutMethodsResponse, Array<PayoutMethod>>(resp => resp.methods
                .sort((a, b) => order.indexOf(a.type) - order.indexOf(b.type))
                .sort((a, b) => +a.isNew - +b.isNew)),
        );
    }

    public saveWithdrawalMethod(orderId: string): Observable<any> {
        if (!orderId) {
            throw new Error("Order id required");
        }
        const url = `${this.billingUrl}/withdrawal/methods`;
        const req: SaveWithdrawalMethodRequest = {orderId};
        return this.http.post(url, req, this.authService.makeTokenAuthHeaders());
    }

    public deleteWithdrawalMethod(methodId: string): Observable<any> {
        console.log("deleteWithdrawalMethod, methodId=", methodId);

        if (!methodId) {
            throw new Error("Method id required");
        }

        const url = `${this.billingUrl}/withdrawal/methods/${methodId}`;
        return this.http.delete(url, this.authService.makeTokenAuthHeaders());
    }

    public createUserIdentityVerification(
            userIdentityVerification: CreateUserIdentityVerificationRequest["args"]["userIdentityVerification"]
    ): Observable<OrderStateEvent<UserIdentityVerificationState>> {
        if (!userIdentityVerification.manual.channel.length) {
            throw new Error("channel text is required");
        }

        const url = `${this.billingUrl}/orders`;
        const req: CreateUserIdentityVerificationRequest = {
            type: OrderType.UserIdentityVerification,
            args: { userIdentityVerification }
        };

        return this.http.post<CreateUserIdentityVerificationResponse>(
                url, req, this.authService.makeTokenAuthHeaders()).pipe(
            switchMap(verificationOrder =>
                combineLatest([
                    this.financeSSEService.orderState$.pipe(
                        filter<OrderStateEvent<UserIdentityVerificationState>>(
                            orderState => orderState.refId === verificationOrder.refId)),
                    of(true),
                ]).pipe(map(([order]) => order))),
        );
    }

    public createWithdrawal(withdrawal: CreateWithdrawalRequest["args"]["withdrawal"]): Observable<OrderStateEvent<WithdrawalState>> {
        if (!withdrawal.amount) {
            throw new Error("Amount required");
        }

        if (!withdrawal.method?.id) {
            throw new Error("Method id required");
        }

        const url = `${this.billingUrl}/orders`;
        const req: CreateWithdrawalRequest = {type: OrderType.Withdrawal, args: {withdrawal}};
        return this.http.post<CreateWithdrawalResponse>(url, req, this.authService.makeTokenAuthHeaders()).pipe(
            switchMap(withdrawalOrder =>
                combineLatest([
                    this.financeSSEService.orderState$.pipe(
                        filter<OrderStateEvent<WithdrawalState>>(
                            orderState => orderState.refId === withdrawalOrder.refId)),
                    of(true),
                ]).pipe(map(([order]) => order))),
        );
    }

    public setOrderState<T extends SSEState>(orderId: string, state: OrderState): Observable<any> {
        if (!orderId) {
            throw new Error("Order id required");
        }
        if (!state) {
            throw new Error("State required");
        }
        const url = `${this.billingUrl}/orders/${orderId}`;
        const req: PutOrderStateRequest = {state};

        return this.http.put<any>(url, req, this.authService.makeTokenAuthHeaders());
    }

    public async confirmPayment(operationId): Promise<void> {
        if (!this.environmentService.isProdBuild) {
            console.log("confirmPayment skipped on dev");
            return;
        }

        await this.getRequest(
            `moneta/confirm?MNT_TRANSACTION_ID=${operationId}`,
            this.confirmPaymentRequest);
    }

    public static getCardType(cc) {
        const cardNum = cc.replace("******", "0000");
        if (!FinanceService.luhnCheck(cardNum)) {
            return "";
        }
        let payCardType = "";
        const regexMap = [
            {regEx: /^4[0-9]{5}/ig, cardType: "VISA"},
            {regEx: /^5[1-5][0-9]{4}/ig, cardType: "MASTERCARD"},
            {regEx: /^3[47][0-9]{3}/ig, cardType: "AMEX"},
            {regEx: /^(5[06-8]\d{4}|6\d{5})/ig, cardType: "MAESTRO"}
        ];

        for (const item of regexMap) {
            if (cardNum.match(item.regEx)) {
                payCardType = item.cardType;
                break;
            }
        }

        if (cardNum.indexOf("50") === 0 || cardNum.indexOf("60") === 0 || cardNum.indexOf("65") === 0) {
            const g = "508500-508999|606985-607984|608001-608500|652150-653149";
            const i = g.split("|");
            for (const item of i) {
                const c = parseInt(item.split("-")[0], 10);
                const f = parseInt(item.split("-")[1], 10);
                if ((cardNum.substr(0, 6) >= c && cardNum.substr(0, 6) <= f) && cardNum.length >= 6) {
                    payCardType = "RUPAY";
                    break;
                }
            }
        }
        return payCardType;
    }

    private static luhnCheck(cardNum) {
        // Luhn Check Code from https://gist.github.com/4075533
        // accept only digits, dashes or spaces
        const numericDashRegex = /^[\d\-\s]+$/;
        if (!numericDashRegex.test(cardNum)) {
            return false;
        }

        // The Luhn Algorithm. It's so pretty.
        let nCheck = 0;
        let nDigit = 0;
        let bEven = false;

        const strippedField = cardNum.replace(/\D/g, "");

        for (let n = strippedField.length - 1; n >= 0; n--) {
            const cDigit = strippedField.charAt(n);
            nDigit = parseInt(cDigit, 10);
            if (bEven) {
                nDigit *= 2;
                if (nDigit > 9) {
                    nDigit -= 9;
                }
            }

            nCheck += nDigit;
            bEven = !bEven;
        }

        return (nCheck % 10) === 0;
    }

    private initDataLayer() {
        this.verificationSuccess$.pipe(takeUntil(this.destroy$)).subscribe(() =>
            this.dataLayerService.emit({
                eventCategory: EventCategory.Identification,
                eventAction: EventAction.Update,
                eventLabel: "result",
                eventValue: "success"
            }));
        this.verificationError$.pipe(takeUntil(this.destroy$)).subscribe(() =>
            this.dataLayerService.emit({
                eventCategory: EventCategory.Identification,
                eventAction: EventAction.Update,
                eventLabel: "result",
                eventValue: "fail"
            }));
    }

    private getWallet(): Observable<Wallet> {
        const url = `${this.baseUrl}/accounts/wallets?fields=balance,usage`;
        return this.http.get<ApiListResponse<Wallet>>(url, this.authService.makeTokenAuthHeaders()).pipe(
            mergeMap(response => iif(() => !response.response.items?.length,
                timer(500).pipe(switchMapTo(this.getWallet())),
                of(response.response.items[0]),
            )),
        );
    }

    public async fetchWallet(): Promise<Wallet> {
        const wallet = await this.getWallet().toPromise();
        if (this.devSettingsService.isUserVerified) {
            wallet.verification.state = VerificationState.VERIFIED;
        }
        return wallet;
    }

    async confirmPhone(code: string): Promise<JsonErrorObject> {
        const purse = await this.fetchWallet();
        if (!purse) {
            return null;
        }

        return await this.postRequest(`accounts/wallets/${purse.refId}/confirm-phone`, {code});
    }

    async sendIdentity(model: IdentityRequest): Promise<Wallet> {
        const purse = await this.fetchWallet();
        if (!purse) {
            return null;
        }

        const url = `accounts/wallets/${purse.refId}`;
        return await this.putRequest(url, model);
    }

}
