import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {AbstractControl, FormControl, FormGroup, ValidationErrors} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {FinanceService} from "../../../services/finance/finance.service";
import {BehaviorSubject, firstValueFrom, Observable, Subject} from "rxjs";
import {distinctUntilChanged, filter, map} from "rxjs/operators";
import {
    CreateUserIdentityVerificationRequest,
    OrderState,
    OrderStateEvent
} from "../../../../../../shared/src/lib/common/FinancialOperation";
import {StorageService} from "ngx-webstorage-service";
import {
    CookiesBackedLocalStorageService
} from "../../../../../../shared/src/lib/services/cookies-backed-local-storage/cookies-backed-local-storage.service";
import * as stream from "node:stream";
import _ from "lodash";

@Component({
    selector: 'app-identity-moneta-transit',
    templateUrl: './identity-moneta-transit.component.html',
    styleUrls: ['./identity-moneta-transit.component.scss']
})
export class IdentityMonetaTransitComponent implements OnInit {

    public step$ = new BehaviorSubject<WizardStep>(WizardStep.Channel);

    public isAboutMeStep$ = this.makeIsStepPipe(WizardStep.AboutMe);
    public isAboutMeStepPassed$ = this.makeIsStepPassedPipe(WizardStep.AboutMe);

    public isChannelStep$ = this.makeIsStepPipe(WizardStep.Channel);
    public isChannelStepPassed$ = this.makeIsStepPassedPipe(WizardStep.Channel);

    public isContactsStep$ = this.makeIsStepPipe(WizardStep.Contacts);
    public isContactsStepPassed$ = this.makeIsStepPassedPipe(WizardStep.Contacts);

    public aboutMeText = this.storageService
        .getItem(ABOUT_ME_STORAGE_KEY)
        ?.substring(0, ABOUT_ME_LENGTH_MAX)
        ?? ""

    public channelText = this.storageService
        .getItem(CHANNEL_STORAGE_KEY)
        ?.substring(0, CHANNEL_LENGTH_MAX)
        ?? ""

    public contactsText = this.storageService
            .getItem(CONTACTS_STORAGE_KEY)
            ?.substring(0, CONTACTS_LENGTH_MAX)
            ?? ""

    public isCompleteButtonEnabled = true;

    public readonly aboutMeFormGroup = new FormGroup({
        aboutMeText: new FormControl<string>(this.aboutMeText)
    });

    public readonly channelFormGroup = new FormGroup({
        channelText: new FormControl<string>(this.channelText, {
            validators: (control: AbstractControl): ValidationErrors => {
                const errors = [];

                if (!control.value?.trim()) {
                    errors.push({ message: "Укажите адрес канала" });
                }

                return errors ? errors : null;
            }
        })
    });

    public readonly contactsFormGroup = new FormGroup({
        contactsText: new FormControl<string>(this.contactsText)
    });

    constructor(
        private readonly financeService: FinanceService,
        private readonly router: Router,
        private readonly storageService: CookiesBackedLocalStorageService,
        private readonly toastr: ToastrService,
    ) {

    }

    ngOnInit(): void {
        this.aboutMeFormGroup.controls.aboutMeText.valueChanges.subscribe(
            v => this.aboutMeText = v);

        this.channelFormGroup.controls.channelText.valueChanges.subscribe(
            v => this.channelText = v);

        this.contactsFormGroup.controls.contactsText.valueChanges.subscribe(
            v => this.contactsText = v);
    }

    public onAboutMeStepClick(): void {
        if (this.channelFormGroup.invalid) {
            return;
        }

        this.step$.next(WizardStep.AboutMe);
    }

    public onChannelStepClick(): void {
        this.step$.next(WizardStep.Channel);
    }

    public onContactsStepClick(): void {
        if (this.channelFormGroup.invalid) {
            return;
        }

        this.step$.next(WizardStep.Contacts);
    }

    public onCloseClick(): void {
        this.router.navigate(["/finance"]);
    }

    public async onCompleteAboutMe(): Promise<void> {
        if (this.channelFormGroup.invalid) {
            return;
        }

        this.storageService.setItem(
            ABOUT_ME_STORAGE_KEY,
            this.aboutMeFormGroup.controls.aboutMeText.value);

        this.isCompleteButtonEnabled = false;

        const aboutMe = this.aboutMeText.substring(0, 500).trim();
        const channel = this.channelText.substring(0, 100).trim();
        const contact = this.contactsText.substring(0, 100).trim();
        const manual = _.omitBy( { aboutMe, channel, contact, }, v => !v );
        const orderState$ = this.financeService
            .createUserIdentityVerification({ manual });

        const isFinalState = (e: OrderStateEvent) =>
            (e.state === OrderState.UserIdentityVerificationInProgress) ||
            e.state.startsWith(OrderState.Failed) ||
            e.state.startsWith(OrderState.Completed);
        const finalState = await firstValueFrom(
            orderState$.pipe(filter(isFinalState)));

        this.isCompleteButtonEnabled = true;

        if (finalState.state === OrderState.UserIdentityVerificationInProgress) {
            this.router.navigate(["/finance"]);
            return;
        }

        this.toastr.error("Что-то пошло не так, попробуйте позже или обратитесь в поддержку ");
    }

    public onConfirmChannel(): void {
        if (this.channelFormGroup.invalid) {
            return;
        }

        this.storageService.setItem(
            CHANNEL_STORAGE_KEY,
            this.channelFormGroup.controls.channelText.value);
        this.step$.next(WizardStep.Contacts);
    }

    public onConfirmContacts(): void {
        if (this.contactsFormGroup.invalid) {
            return;
        }

        this.storageService.setItem(
            CONTACTS_STORAGE_KEY,
            this.contactsFormGroup.controls.contactsText.value);
        this.step$.next(WizardStep.AboutMe);
    }

    private makeIsStepPipe(step: WizardStep): Observable<Boolean> {
        return this.step$.pipe(
            distinctUntilChanged(),
            map(v => v === step));
    }

    private makeIsStepPassedPipe(step: WizardStep): Observable<Boolean> {
        return this.step$.pipe(
            distinctUntilChanged(),
            map(v => v > step));
    }
}

enum WizardStep {
    Channel,
    Contacts,
    AboutMe
}

const ABOUT_ME_STORAGE_KEY = "donatty/user-verification/moneta-transit/about-me";
const CHANNEL_STORAGE_KEY = "donatty/user-verification/moneta-transit/channel";
const CONTACTS_STORAGE_KEY = "donatty/user-verification/moneta-transit/contacts";

const ABOUT_ME_LENGTH_MAX = 500;
const CHANNEL_LENGTH_MAX = 100;
const CONTACTS_LENGTH_MAX = 100;

