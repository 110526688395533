import {Currency} from "../common/FinancialOperation";
import {BillingLimits} from "../../../../streamer/src/app/services/finance/account-limits";

export interface IdentityPassport {
    series: string;
    id: string;
}

export interface IdentityRequest {
    birthDate?: string;
    familyName: string;
    givenName: string;
    inn?: string;
    passport: IdentityPassport;
    phone: string;
    snils?: string;
}

export enum VerificationState {
    UNVERIFIED = "UNVERIFIED",
    VERIFYING = "VERIFYING",
    VERIFIED = "VERIFIED",
    FAILURE = "FAILURE",
    NOT_APPLICABLE = "INAPPLICABLE",

    // legacy wallet system states
    LEGACY_PHONE = "PHONE",
    LEGACY_CHECKING = "CHECKING",
}

export const finalVerificationStates = [
    VerificationState.VERIFIED, VerificationState.NOT_APPLICABLE];

export interface WalletMonthUsage {
    fee: number;
    withdrawal: number;
}

export interface HasBalance {
    balance: number;
}

export interface BillingLimitsUpdatedEvent {
    limits: Array<BillingLimits>;
    currentId: string;
}

export interface AccountStatistics {
    total: {
        donations: number;
    };
    months: Array<AccountStatisticsMonth>;
}

export interface AccountStatisticsMonth {
    date: string;
    donations: AccountStatisticsMonthDonations;
}

export interface AccountStatisticsMonthDonations {
    total: number;
    current: number;
}

interface LegacyTariffTariffRate {
    rates: { [key in PaymentMethodType]?: any };
    min?: number;
    max?: number;
    monthLimit: number;
}

export interface LegacyTariff {
    name: string;
    in: LegacyTariffTariffRate;
    out: LegacyTariffTariffRate;
    balanceLimit: number;
}

export interface Wallet {
    refId: string;
    currency: Currency;

    balance?: number;
    usage?: WalletMonthUsage;

    tariff?: LegacyTariff;
    verification?: {
        state: VerificationState;
        errors?: string[];
    };
    phoneConfirmed?: boolean;
}

export enum PaymentMethodType {
    Card = "CARD",
    Qiwi = "QIWI",
    Yandex = "YANDEX",
    Google = "GOOGLE",
    Apple = "APPLE",
    Paypal = "PAYPAL",
}

export enum BillingSystemType {
    MonetaLegacy = "MONETA_LEGACY",
    MonetaTransit = "MONETA_TRANSIT",
}

export interface PaymentMethod {
    id: string;
    billingSystemType: BillingSystemType;
    type: PaymentMethodType;
}
