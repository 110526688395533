import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from "@angular/core";
import {
    BillingSystemType,
    finalVerificationStates,
    VerificationState
} from "../../../../../../shared/src/lib/models/finance.api";
import {FinanceService} from "../../../services/finance/finance.service";
import {BehaviorSubject, combineLatest, merge, Observable, Subject} from "rxjs";
import {filter, map, shareReplay, takeUntil} from "rxjs/operators";
import {UserService} from "../../../services/user/user.service";
import {FinanceSSEService} from "../../../services/finance/finance-sse.service";
import {Currency} from "../../../../../../shared/src/lib/common/FinancialOperation";
import {AccountLimits} from "../../../services/finance/account-limits";

enum BalanceHint { InProgress = "inProgress", Failure = "Failure", Promote = "Promote"}

@Component({
    selector: "app-balance",
    templateUrl: "./balance.component.html",
    styleUrls: ["./balance.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BalanceComponent implements OnInit, OnDestroy {
    private preemption = {
        account: 500,
        monthly: 1000,
    };
    public readonly VerificationState = VerificationState;

    private readonly destroy$: Subject<void> = new Subject();

    public readonly flipped$ = new BehaviorSubject(false);

    public readonly limits$: Observable<AccountLimits> = this.financeSSEService.limits$;

    public readonly verificationState$: Observable<VerificationState> = this.financeSSEService.verificationState$
        .pipe(filter<VerificationState>(Boolean), shareReplay(1));

    private readonly usageLimits$: Observable<[number, AccountLimits]> =
        combineLatest([this.financeSSEService.usageCurrent$, this.limits$]).pipe(shareReplay(1));

    public readonly payoutAvailable$: Observable<boolean> = this.verificationState$.pipe(
        map(verificationState => finalVerificationStates.includes(verificationState)),
        shareReplay(1),
    );

    public readonly isAccountLimitReached$: Observable<boolean> = this.financeSSEService.balanceLimits$
        .pipe(map<[number, AccountLimits], boolean>(([balance, limits]) =>
            Math.ceil(balance) >= Math.floor(limits.current.balance.max - this.preemption.account)));

    public readonly isMonthlyLimitReached$: Observable<boolean> = this.usageLimits$
        .pipe(map<[number, AccountLimits], boolean>(([usage, limits]) =>
            Math.ceil(usage || 0) >= Math.floor(limits.current.credit.month - this.preemption.monthly)));

    public readonly isAnyLimitReached$: Observable<boolean> =
        combineLatest([this.isAccountLimitReached$, this.isMonthlyLimitReached$])
            .pipe(map(([total, month]) => total || month), shareReplay(1));

    public readonly isWithdrawalDisabled$: Observable<boolean> =
        combineLatest([this.userService.permissions$, this.financeSSEService.rates$])
            .pipe(map(([isPermitted, rates]) =>
                !isPermitted || !rates.debitRates));

    public readonly limitProgress$: Observable<number | null> =
        this.financeSSEService.balanceLimits$.pipe(
            filter(([, limits]) => !!limits.current.balance.max),
            map(([balance, limits]) =>
                limits.current.balance.max ? balance / limits.current.balance.max * 100 : null),
        );

    public readonly monthLimitProgress$ = this.usageLimits$
        .pipe(map(([usage, limits]) =>
            (usage || 0) / limits.current.credit.month * 100));

    public readonly progressGradient$ = this.financeSSEService.balanceLimits$
        .pipe(map(([balance, limits]) =>
            BalanceComponent.renderProgressBarImage(balance / limits.current.balance.max)));

    public readonly monthLimitProgressGradient$ = this.usageLimits$
        .pipe(map(([usage, limits]) =>
            BalanceComponent.renderProgressBarImage((usage || 0) / limits.current.credit.month)));

    public readonly hint$: Observable<BalanceHint> = combineLatest([
        this.verificationState$, this.userService.permissions$,
    ]).pipe(
        map(([verificationState, permissions]) => {
            if (!permissions || finalVerificationStates.includes(verificationState)) {
                return null;
            }
            if (verificationState === VerificationState.VERIFYING) {
                return BalanceHint.InProgress;
            }
            if (verificationState === VerificationState.FAILURE) {
                return BalanceHint.Failure;
            }
            return BalanceHint.Promote;
        })
    );

    public readonly hasLimits$ = this.limits$.pipe(map(limits =>
        limits.current.balance.max > 0 ||
        limits.current.credit.max > 0 ||
        limits.current.credit.month > 0,
    ));

    public readonly identityLink$ = this.financeSSEService
        .billingSystemType$.pipe(map(type => {
            switch (type) {
                case BillingSystemType.MonetaLegacy: return "/identity";
                case BillingSystemType.MonetaTransit: return "/identity/moneta_transit";
            }
        }))

    public readonly currency = Currency.RUB;
    public readonly BalanceHint = BalanceHint;

    constructor(public readonly financeService: FinanceService,
                public readonly financeSSEService: FinanceSSEService,
                public readonly userService: UserService,
                public readonly changeDetectorRef: ChangeDetectorRef) {
    }

    public ngOnInit() {
        merge(this.financeSSEService.balance$)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => setTimeout(() => this.changeDetectorRef.detectChanges()));
    }

    public ngOnDestroy() {
        this.destroy$.next();
    }

    private static renderProgressBarImage(ratio: number): string {
        ratio = Math.min(ratio, 1);
        const min = [0x64, 0xb4, 0xf3];
        const max = [0xeb, 0x4e, 0x45];

        const c = [
            Math.ceil((max[0] * ratio) + (min[0] * (1 - ratio))),
            Math.ceil((max[1] * ratio) + (min[1] * (1 - ratio))),
            Math.ceil((max[2] * ratio) + (min[2] * (1 - ratio))),
        ];

        c[0] = Math.min(c[0], 0xff);
        c[1] = Math.min(c[1], 0xff);
        c[2] = Math.min(c[2], 0xff);

        const from = (
            min[0].toString(16) +
            min[1].toString(16) +
            min[2].toString(16));
        const to = (
            c[0].toString(16) +
            c[1].toString(16) +
            c[2].toString(16));
        return `linear-gradient(to right, #${from}, #${to})`;
    }

}
